p {
  font-size: 8rem;
}

.landingpagebox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
  width: 32.4rem;
  border-radius: 4rem;
  text-align: center;
  background-color: #ffffff;
  border: 0.3rem solid rgb(25, 25, 32, 1);
  max-width: 100%;
}

.landingpagebox h2 {
  font-family: "Space Mono", Helvetica, sans-serif;
  color: #000000;
}

a {
  text-decoration: none;
}

a .landingpagebox:hover {
  background-color: rgb(25, 25, 32, 1);
  border-color: #ffffff;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(255, 255, 255, 0.6);
  h2 {
    color: #ffffff;
  }
}

.nothing {
  color: #ffffff;
  display: flex;
  justify-content: center;
}

footer {
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin-top: 45%;
}

@media (max-width: 480px) {
  .landingpagebox {
    width: auto;
    height: auto;
  }
  footer {
    margin-top: 60%;
  }
}
